import { render, staticRenderFns } from "./Educator.vue?vue&type=template&id=0a9f3f43&scoped=true"
import script from "./Educator.vue?vue&type=script&lang=js"
export * from "./Educator.vue?vue&type=script&lang=js"
import style0 from "./Educator.vue?vue&type=style&index=0&id=0a9f3f43&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a9f3f43",
  null
  
)

export default component.exports